/*@font-face {
    font-family: 'agorafonts';
    src: url('../fonts/agorafonts.eot');
    src: url('../fonts/agorafonts.ttf') format('truetype'), url('../fonts/agorafonts.woff') format('woff'), url('../fonts/agorafonts.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}*/

@font-face {
  font-family: 'agorafonts';
  src:  url('../fonts/agorafonts.eot');
  src:  url('../fonts/agorafonts.eot') format('embedded-opentype'),
    url('../fonts/agorafonts.ttf') format('truetype'),
    url('../fonts/agorafonts.woff') format('woff'),
    url('../fonts/agorafonts.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}



[class^="aga-"], [class*=" aga-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'agorafonts' !important;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.aga-button--icon {
    border-radius: 50%;
    font-size: 24px;
    height: 32px;
    margin-left: 0;
    margin-right: 0;
    min-width: 32px;
    width: 32px;
    padding: 0;
    overflow: hidden;
    color: inherit;
    line-height: normal;
    cursor:pointer;
}
.aga {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
[data-agora-icon-right]:after {
    font-family: 'agorafonts' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 24px;
    letter-spacing: normal;
    word-wrap: normal;
    vertical-align: middle;
    margin: 0 0.2rem 0 0;
    transform: translateY(-1px);
}


.aga-icon-xs {
    font-size: 12px;
}
.aga-icon-s {
    font-size: 15px;
}
.aga-icon-l {
    font-size: 18px;
}

.aga-icon-ml {
    font-size: 20px;
}

.aga-icon-xl {
    font-size: 30px;
}


.aga-file-pen1:before {
  content: "\e9b1";
}

.aga-file-circle-plus:before {
    content: "\e9b0";
}
.aga-raindrops:before {
  content: "\e9ae";
}
.aga-snowflake:before {
  content: "\e9af";
}

.aga-pen:before {
  content: "\e9aa";
}
.aga-penfill:before {
  content: "\e9ab";
}
.aga-trash:before {
  content: "\e9ac";
}
.aga-trash-fill:before {
  content: "\e9ad";
}

.aga-thumbs-down1:before {
  content: "\e9a8";
}
.aga-thumbs-up1:before {
  content: "\e9a9";
}

.aga-file-pen:before {
  content: "\e9a7";
}

.aga-thumbs-down:before {
  content: "\e9a5";
}
.aga-thumbs-up:before {
  content: "\e9a6";
}

.aga-envelope:before {
  content: "\e99e";
}
.aga-phone1:before {
  content: "\e99f";
}
.aga-user:before {
  content: "\e9a0";
}
.aga-chart-column:before {
  content: "\e9a1";
}
.aga-user-tie:before {
  content: "\e9a2";
}
.aga-house-lock:before {
  content: "\e9a3";
}
.aga-house-circle-check:before {
  content: "\e9a4";
}
.aga-directionsbus:before {
  content: "\e99d";
}
.aga-streetview:before {
  content: "\e993";
}
.aga-satellite:before {
  content: "\e994";
}
.aga-poi:before {
  content: "\e995";
}
.aga-destination:before {
  content: "\e996";
}
.aga-transit:before {
  content: "\e997";
}
.aga-walk:before {
  content: "\e998";
}
.aga-driving:before {
  content: "\e999";
}
.aga-pdffile:before {
  content: "\e99a";
}
.aga-link:before {
  content: "\e99b";
}
.aga-wi-dewpoint:before {
  content: "\e99c";
}
.aga-wi-strong-wind:before {
  content: "\e94b";
}
.aga-wi-thermometer:before {
  content: "\e94d";
}
.aga-wi-night-rain:before {
  content: "\e982";
}
.aga-wi-night-sleet-storm:before {
  content: "\e983";
}
.aga-wi-night-storm-showers:before {
  content: "\e984";
}
.aga-wi-raindrop:before {
  content: "\e985";
}
.aga-wi-night-alt-cloudy:before {
  content: "\e986";
}
.aga-wi-night-alt-rain-mix:before {
  content: "\e987";
}
.aga-wi-night-alt-thunderstorm:before {
  content: "\e988";
}
.aga-wi-night-clear:before {
  content: "\e989";
}
.aga-wi-humidity:before {
  content: "\e98a";
}
.aga-wi-day-sleet-storm:before {
  content: "\e98b";
}
.aga-wi-day-storm-showers1:before {
  content: "\e98c";
}
.aga-wi-day-sunny:before {
  content: "\e98d";
}
.aga-wi-day-thunderstorm:before {
  content: "\e98e";
}
.aga-wi-day-cloudy:before {
  content: "\e98f";
}
.aga-wi-day-rain:before {
  content: "\e990";
}
.aga-wi-day-rain-mix:before {
  content: "\e991";
}
.aga-wi-day-storm-showers:before {
  content: "\e992";
}

.aga-Al:before {
  content: "\e980";
  color: #00477f;
}
.aga-bed1:before {
  content: "\e981";
}
.aga-bath:before {
  content: "\ec50";
}
.aga-bed11:before {
  content: "\ec51";
}
.aga-draw-square:before {
  content: "\ec52";
}
.aga-house:before {
  content: "\ec53";
}
.aga-ruler-triangle:before {
  content: "\ec54";
}


.aga-circle-check-regular:before {
  content: "\e97f";
}

.aga-book-open-cover:before {
  content: "\e97d";
}
.aga-briefcase-solid:before {
  content: "\e97e";
}

.aga-arrow-up-wide-short:before {
  content: "\e97b";
}
.aga-arrow-down-short-wide:before {
  content: "\e97c";
}

.aga-arrow-down-arrow-up:before {
  content: "\e97a";
}

.aga-road-solid:before {
  content: "\e976";
}
.aga-flag-solid:before {
  content: "\e977";
}
.aga-bookmark-solid:before {
  content: "\e978";
}
.aga-users-solid:before {
  content: "\e979";
}
.aga-line:before {
  content: "\e969";
}
.aga-ROGcoin:before {
  content: "\e96a";
  color: #c6aa5f;
}
.aga-ROGregular:before {
  content: "\e96b";
  color: #c6aa5f;
}
.aga-bhhs_seal_blk:before {
  content: "\e96c";
}
.aga-house-chimney-user:before {
  content: "\e96f";
}
.aga-house-heart:before {
  content: "\e970";
}
.aga-city:before {
  content: "\e971";
}
.aga-chart-user:before {
  content: "\e972";
}
.aga-circle-arrow-down:before {
  content: "\e973";
}
.aga-circle-arrow-up:before {
  content: "\e974";
}
.aga-alarm-clock:before {
  content: "\e975";
}
.aga-sliders:before {
  content: "\e96d";
}
.aga-arrow-right:before {
  content: "\e96e";
}


/*.aga-slack:before {
  content: "\e969";
}*/
.aga-whatsapp:before {
  content: "\e967";
}
.aga-messenger:before {
  content: "\e968";
}
.aga-squarespace:before {
  content: "\e959";
}
.aga-tiktok:before {
  content: "\e958";
}
.aga-home1:before {
  content: "\f1fc";
}
.aga-enterprise-network:before {
  content: "\e004";
}
.aga-equal-housing:before {
  content: "\e005";
}
.aga-realtor:before {
  content: "\e006";
}
.aga-acres:before {
  content: "\e901";
}
.aga-bathtub:before {
  content: "\e902";
}
.aga-bed:before {
  content: "\e903";
}
.aga-lot-square:before {
  content: "\e904";
}
.aga-scale:before {
  content: "\e905";
}
.aga-error-outline:before {
  content: "\e906";
}
.aga-play-circle-outline:before {
  content: "\e907";
}
.aga-business:before {
  content: "\e908";
}
.aga-phone:before {
  content: "\e909";
}
.aga-clear-all:before {
  content: "\e90a";
}
.aga-email:before {
  content: "\e90b";
}
.aga-location:before {
  content: "\e90c";
}
.aga-phone-ring:before {
  content: "\e90d";
}
.aga-add:before {
  content: "\e90e";
}
.aga-close:before {
  content: "\e90f";
}
.aga-edit:before {
  content: "\e910";
}
.aga-remove:before {
  content: "\e911";
}
.aga-save:before {
  content: "\e912";
}
.aga-gps:before {
  content: "\e913";
}
.aga-keyboard-arrow-down:before {
  content: "\e914";
}
.aga-keyboard-arrow-left:before {
  content: "\e915";
}
.aga-keyboard-arrow-right:before {
  content: "\e916";
}
.aga-keyboard-arrow-up:before {
  content: "\e917";
}
.aga-phone-iphone:before {
  content: "\e918";
}
.aga-directions:before {
  content: "\e919";
}
.aga-map:before {
  content: "\e91a";
}
.aga-satellite:before {
  content: "\e91b";
}
.aga-fullscreen:before {
  content: "\e91c";
}
.aga-fullscreen-exit:before {
  content: "\e91d";
}
.aga-menu:before {
  content: "\e91e";
}
.aga-person:before {
  content: "\e91f";
}
.aga-public:before {
  content: "\e920";
}
.aga-account-box:before {
  content: "\e921";
}
.aga-favorite:before {
  content: "\e922";
}
.aga-favorite-outline:before {
  content: "\e923";
}
.aga-search:before {
  content: "\e924";
}
.aga-grid:before {
  content: "\e925";
}
.aga-radio-button-unchecked:before {
  content: "\e926";
}
.aga-print:before {
  content: "\e927";
}
.aga-share:before {
  content: "\e928";
}
.aga-border-bottom:before {
  content: "\e929";
}
.aga-collectionsphoto-library:before {
  content: "\e92a";
}
.aga-color-lenspalette:before {
  content: "\e92b";
}
.aga-more-vert:before {
  content: "\e92c";
}
.aga-notifications:before {
  content: "\e92d";
}
.aga-account-circle:before {
  content: "\e92e";
}
.aga-done:before {
  content: "\e92f";
}
.aga-done-all:before {
  content: "\e930";
}
.aga-exit:before {
  content: "\e931";
}
.aga-list:before {
  content: "\e932";
}
.aga-loyalty:before {
  content: "\e933";
}
.aga-settings:before {
  content: "\e934";
}
.aga-settings-input:before {
  content: "\e935";
}
.aga-supervisor:before {
  content: "\e936";
}
.aga-home:before {
  content: "\e937";
}
.aga-attach-money:before {
  content: "\e938";
}
.aga-local-shipping:before {
  content: "\e939";
}
.aga-location-city:before {
  content: "\e93a";
}
.aga-thumb-up-alt:before {
  content: "\e93b";
}
.aga-account-balance:before {
  content: "\e93c";
}
.aga-home-filled:before {
  content: "\e93d";
}
.aga-supervisor-account:before {
  content: "\e93e";
}
.aga-arrow-left:before {
  content: "\f060";
}
.aga-arrow-right1:before {
  content: "\f061";
}
.aga-arrow-up:before {
  content: "\f062";
}
.aga-arrow-down:before {
  content: "\f063";
}
.aga-phone-square:before {
  content: "\f098";
}
.aga-twitter:before {
  content: "\f099";
}
.aga-facebook:before {
  content: "\f09a";
}
.aga-rss:before {
  content: "\f09e";
}
.aga-google-plus:before {
  content: "\f0d5";
}
.aga-linkedin:before {
  content: "\f0e1";
}
.aga-chevron-circle-down1:before {
  content: "\f13a";
}
.aga-youtube:before {
  content: "\f167";
}
.aga-youtube-play:before {
  content: "\f16a";
}
.aga-instagram:before {
  content: "\f16e";
}
.aga-skype:before {
  content: "\f17e";
}
.aga-language:before {
  content: "\f1ac";
}
.aga-pininterest:before {
  content: "\f231";
}
.aga-vimeo:before {
  content: "\f27d";
}
.aga-arrow-right:before {
  content: "\ea34";
}
.aga-blogger2:before {
  content: "\eab8";
}
.aga-file-pdf:before {
  content: "\eadf";
}
.aga-video-camera2:before {
  content: "\e94d";
}
.aga-home1:before {
  content: "\f1fc";
}
.aga-enterprise-network:before {
  content: "\e004";
}
.aga-equal-housing:before {
  content: "\e005";
}
.aga-realtor:before {
  content: "\e006";
}
.aga-acres:before {
  content: "\e901";
}
.aga-bathtub:before {
  content: "\e902";
}
.aga-bed:before {
  content: "\e903";
}
.aga-lot-square:before {
  content: "\e904";
}
.aga-scale:before {
  content: "\e905";
}
.aga-error-outline:before {
  content: "\e906";
}
.aga-play-circle-outline:before {
  content: "\e907";
}
.aga-business:before {
  content: "\e908";
}
.aga-phone:before {
  content: "\e909";
}
.aga-clear-all:before {
  content: "\e90a";
}
.aga-email:before {
  content: "\e90b";
}
.aga-location:before {
  content: "\e90c";
}
.aga-phone-ring:before {
  content: "\e90d";
}
.aga-add:before {
  content: "\e90e";
}
.aga-close:before {
  content: "\e90f";
}
.aga-edit:before {
  content: "\e910";
}
.aga-remove:before {
  content: "\e911";
}
.aga-save:before {
  content: "\e912";
}
.aga-gps:before {
  content: "\e913";
}
.aga-keyboard-arrow-down:before {
  content: "\e914";
}
.aga-keyboard-arrow-left:before {
  content: "\e915";
}
.aga-keyboard-arrow-right:before {
  content: "\e916";
}
.aga-keyboard-arrow-up:before {
  content: "\e917";
}
.aga-phone-iphone:before {
  content: "\e918";
}
.aga-directions:before {
  content: "\e919";
}
.aga-map:before {
  content: "\e91a";
}
.aga-satellite:before {
  content: "\e91b";
}
.aga-fullscreen:before {
  content: "\e91c";
}
.aga-fullscreen-exit:before {
  content: "\e91d";
}
.aga-menu:before {
  content: "\e91e";
}
.aga-person:before {
  content: "\e91f";
}
.aga-public:before {
  content: "\e920";
}
.aga-account-box:before {
  content: "\e921";
}
.aga-favorite:before {
  content: "\e922";
}
.aga-favorite-outline:before {
  content: "\e923";
}
.aga-search:before {
  content: "\e924";
}
.aga-grid:before {
  content: "\e925";
}
.aga-radio-button-unchecked:before {
  content: "\e926";
}
.aga-print:before {
  content: "\e927";
}
.aga-share:before {
  content: "\e928";
}
.aga-border-bottom:before {
  content: "\e929";
}
.aga-collectionsphoto-library:before {
  content: "\e92a";
}
.aga-color-lenspalette:before {
  content: "\e92b";
}
.aga-more-vert:before {
  content: "\e92c";
}
.aga-notifications:before {
  content: "\e92d";
}
.aga-account-circle:before {
  content: "\e92e";
}
.aga-done:before {
  content: "\e92f";
}
.aga-done-all:before {
  content: "\e930";
}
.aga-exit:before {
  content: "\e931";
}
.aga-list:before {
  content: "\e932";
}
.aga-loyalty:before {
  content: "\e933";
}
.aga-settings:before {
  content: "\e934";
}
.aga-settings-input:before {
  content: "\e935";
}
.aga-supervisor:before {
  content: "\e936";
}
.aga-home:before {
  content: "\e937";
}
.aga-attach-money:before {
  content: "\e938";
}
.aga-local-shipping:before {
  content: "\e939";
}
.aga-location-city:before {
  content: "\e93a";
}
.aga-thumb-up-alt:before {
  content: "\e93b";
}
.aga-account-balance:before {
  content: "\e93c";
}
.aga-home-filled:before {
  content: "\e93d";
}
.aga-supervisor-account:before {
  content: "\e93e";
}
.aga-arrow-left:before {
  content: "\f060";
}
.aga-arrow-right1:before {
  content: "\f061";
}
.aga-arrow-up:before {
  content: "\f062";
}
.aga-arrow-down:before {
  content: "\f063";
}
.aga-phone-square:before {
  content: "\f098";
}
.aga-twitter:before {
  content: "\f099";
}
.aga-facebook:before {
  content: "\f09a";
}
.aga-rss:before {
  content: "\f09e";
}
.aga-google-plus:before {
  content: "\f0d5";
}
.aga-linkedin:before {
  content: "\f0e1";
}
.aga-chevron-circle-down1:before {
  content: "\f13a";
}
.aga-youtube:before {
  content: "\f167";
}
.aga-youtube-play:before {
  content: "\f16a";
}
.aga-instagram:before {
  content: "\f16e";
}
.aga-skype:before {
  content: "\f17e";
}
.aga-language:before {
  content: "\f1ac";
}
.aga-pininterest:before {
  content: "\f231";
}
.aga-vimeo:before {
  content: "\f27d";
}
.aga-video-recording:before {
  content: "\e94b";
}
.aga-play_arrow:before {
  content: "\e95c";
}
.aga-computer:before {
  content: "\e95d";
}
.aga-arrow_drop_down:before {
  content: "\e95e";
}
.aga-event_note:before {
  content: "\e95f";
}
.aga-pest_control:before {
  content: "\e960";
}
.aga-update:before {
  content: "\e95a";
}
.aga-lightbulb_outline:before {
  content: "\e95b";
}
.aga-watch_later:before {
  content: "\e954";
}
.aga-select_all:before {
  content: "\e955";
}
.aga-school:before {
  content: "\e956";
}
.aga-content_copy:before {
  content: "\e957";
}
.aga-compare:before {
  content: "\e953";
}
.aga-chat:before {
  content: "\e950";
}
.aga-description:before {
  content: "\e951";
}
.aga-trending-up:before {
  content: "\e952";
}
.aga-open-new:before {
  content: "\e94f";
}
.aga-pause:before {
  content: "\e94e";
}
.aga-visibility:before {
  content: "\e942";
}
.aga-chrome-reader-mode:before {
  content: "\e945";
}
.aga-dns:before {
  content: "\e944";
}
.aga-visibility-off:before {
  content: "\e943";
}
.aga-language-globe:before {
  content: "\e941";
}
.aga-camera1:before {
  content: "\e93f";
}
.aga-3d_rotation:before {
  content: "\e940";
}
.aga-help:before {
  content: "\e900";
}
.aga-info_outline:before {
  content: "\e946";
}
.aga-cloud_download:before {
  content: "\e947";
}
.aga-backspace:before {
  content: "\e948";
}
.aga-delete:before {
  content: "\e949";
}
.aga-videocam:before {
  content: "\e965";
}
.aga-web:before {
  content: "\e961";
}
.aga-file_download:before {
  content: "\e963";
}
.aga-star:before {
  content: "\e966";
}
.aga-account_balance_wallet:before {
  content: "\e964";
}
.aga-picture_in_picture:before {
  content: "\e962";
}
.aga-refresh:before {
  content: "\f021";
}
.aga-check-circle-o:before {
  content: "\f05d";
}
.aga-chevron-circle-up:before {
  content: "\f139";
}
.aga-chevron-circle-down:before {
  content: "\f13b";
}
.aga-video-camera:before {
  content: "\e94c";
}
.aga-times-circle:before {
  content: "\e94a";
}
.aga-snapchat-ghost:before {
  content: "\f2ac";
}
.aga-angle-double-left:before {
  content: "\f100";
}
.aga-angle-double-right:before {
  content: "\f101";
}
 